import React from 'react';
import Grid from '@material-ui/core/Grid'; 
import YouTube from 'react-youtube';

import GameSticky from './GameSticky.js';
import GameShots from './GameShots.js';


class BaseApp extends React.Component {


    createHeadVideo(){
        const gameOpts = {
            width: '100%',
            //height: '400%',
            playerVars: { // https://developers.google.com/youtube/player_parameters
            autoplay: 1
            }
        };
          if(this.props.videoId != null)
          {
          return(
            <Grid item xs={12} className = 'screen-shot'>
                <div>
                    <YouTube videoId={this.props.videoId} opts={gameOpts} onReady={this._onReady}/>
                </div>
            </Grid>
          );
          }
        //eturn table
      }

    createTailVideo(){
        const tailVideoOpts = {
            width: '100%',
            //height: '400%',
            playerVars: { // https://developers.google.com/youtube/player_parameters
            autoplay: 0
            }
        };
          if(this.props.tailVideoId != null)
          {
          return(
            <Grid container spacing={1}>
                <Grid item xs={12} className = 'screen-shot'>
                <div>
                    <YouTube videoId={this.props.tailVideoId} opts={tailVideoOpts} onReady={this._onReady}/>
                </div>
                </Grid>
            </Grid>
          );
          }
        //eturn table
      }

    render() {
        

        
    return (
        <div>
            <GameSticky appName = {this.props.appName}
                appStoreLocation = {this.props.appStoreLocation}
                playStoreLocation = {this.props.playStoreLocation}
                />
            <Grid container spacing={1}>
                <Grid item xs={12} className = 'screen-shot'>
                    <div className = 'Game-short-description'>
                        {this.props.shortDescription}
                    </div>
                </Grid>
            {this.createHeadVideo()}
            <Grid item xs={12} className = 'screen-shot'>
                <div className = 'Game-long-description'>
                    {this.props.longDescription}
                </div>
                </Grid>
            </Grid>

            <GameShots appName = {this.props.appName} shots = {this.props.shots}/>

            {this.createTailVideo()}
          
        </div>
      );
    
    
    }

    _onReady(event) {
      // access to player in all event handlers via event.target
      console.log("on_Ready");
      //event.target.pauseVideo();
    }

    _onStop(event) {
      // access to player in all event handlers via event.target
      console.log("on_stop");
      //event.target.pauseVideo();
    }

  }

export default BaseApp;
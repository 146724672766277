import React from 'react';
import Grid from '@material-ui/core/Grid'; 

import {
    isMobile
  } from "react-device-detect";

class GameShots extends React.Component {

    createTable(){
        let table = []
    
        // Outer loop to create parent
        if(this.props.shots != null)
        for (let i = 0; i < this.props.shots.length; i++) 
        {
          
          table.push(
            <Grid item xs={this.gridWidth} className = 'screen-shot' key = {i}>
                <div>
                    <img className = 'Game-app-shot' src={this.props.shots[i]}  alt={this.props.appName +'- Screen Shot '+i+' - Abnormal Head LLC'}/>
                </div>
            </Grid>);
        }
        return table
      }

    render() {
        if(isMobile)
        {
            this.gridWidth = 12;
        }
        else 
        {
            this.gridWidth = 6;
        }

        return(
            <Grid container spacing={1}>
                {this.createTable()}
            </Grid>
        );
    }
}

export default GameShots;
import React from 'react';
import { Route } from 'react-router';

import AppSlider from './Slideshow.js';

import WindHeroes from './Wind-Heroes.js';
import MTile from './M-Tile.js';
import TTile from './Time-Tile.js';
import GhostBurner from './Ghost-Burner.js';
import OfficeUndead from './Office-Undead.js';

import AboutUs from './About-Us.js';
import ContactUs from './Contact-Us.js';
import News from './News.js';
import Privacy from './Privacy.js';
import Support from './Support.js';
import Apps from './Apps.js';

import {
    isMobile
  } from "react-device-detect";

import {
    Router,
    Switch,
  } from "react-router-dom";

  import ReactGA from 'react-ga';
  import createHistory from 'history/createBrowserHistory'

  var slides = undefined;

if(!isMobile)
{
  slides = [
  {
    key:0,
    appname: 'Wind Heroes',
    subtitle: '3d immersive Augmented Reality',
    href: '/apps/wind-heroes',
    img: '/slider-wh-desktop.jpg',
  },
  
  {
    key:1,
    appname: 'M-Tile',
    subtitle: 'An addictive letter tile game',
    href: '/apps/m-tile',
    img: '/slider-mtile-desktop.jpg',
  },
  /*
  {
    key:2,
    appname: 'Ghost Burner',
    subtitle: 'Burn through the ghostly ranks',
    href: '/apps/ghost-burner',
    img: '/images/ghost-burner.jpg',
  },
  */
  ];
  }
  else
  {
    slides = [
      {
        key:0,
        appname: 'Wind Heroes',
        subtitle: '3d immersive Augmented Reality',
        href: '/apps/wind-heroes',
        img: '/slider-wh-mobile.jpg',
      },
      
      {
        key:1,
        appname: 'M-Tile',
        subtitle: 'An addictive letter tile game',
        href: '/apps/m-tile',
        img: '/slider-mtile-mobile.jpg',
      },
      /*
      {
        key:2,
        appname: 'Ghost Burner',
        subtitle: 'Burn through the ghostly ranks',
        href: '/apps/ghost-burner',
        img: '/images/ghost-burner.jpg',
      },
      */
      ];
  }

 /*
     
 */


const history = createHistory();

// Initialize google analytics page view tracking
history.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});



export default (
    <Router history={history}>
    <Switch>
        <Route exact path="/"><AppSlider slides={slides} className="App-slideshow"/></Route>
        <Route path="/about-us"><AboutUs/></Route>
        <Route path="/contact-us"><ContactUs/></Route>
        <Route path="/news"><News/></Route>
        <Route exact path="/apps"><Apps/></Route>
        <Route exact path="/apps/"><Apps/></Route>
        <Route path="/privacy"><Privacy/></Route>
        <Route path="/support"><Support/></Route>
        <Route path="/apps/wind-heroes"><WindHeroes/></Route>
        <Route path="/apps/ghost-burner"><GhostBurner/></Route>
        <Route path="/apps/time-tile"><TTile/></Route>
        <Route path="/wind-heroes-coming-soon"><WindHeroes/></Route>
        <Route path="/office-undead/"><OfficeUndead/></Route>
        <Route path="/apps/m-tile"><MTile/></Route>wind-heroes-coming-soon
      </Switch>
    </Router>
);
import React from 'react';
import Grid from '@material-ui/core/Grid'; 

import Sticky from '@wicked_query/react-sticky';

import {
    isMobile
  } from "react-device-detect";


class GameSticky extends React.Component {

    render() {
        var AppStore = require('./images/App_Store_Badge_US.png');
        if(this.props.playStoreLocation == null)
        {
            if(!isMobile)
            {
            return(
                <Sticky subscribe={(props) => (this.setState({ offset: props.height }))} addClassName={'small'}>
                    <Grid container spacing={1} >
                        <Grid item xs={6} className = 'Game-ios-sticky'>
                            <a className = 'Game-ios-sticky' href={this.props.appStoreLocation} target="itunes_store"><img src={AppStore} alt={this.appName+' - Abnormal Head LLC'}/></a>
                        </Grid>
                        <Grid item xs={6} className = 'Game-ios-sticky'>
                            <div className = 'Game-title'>{this.props.appName}</div>
                        </Grid>
                    </Grid>
                </Sticky>
            );
            }
            else
            {
                return (
                    <Sticky subscribe={(props) => (this.setState({ offset: props.height }))} addClassName={'small'}>
                    <Grid container spacing={1} >
                        <Grid item xs={12} className = 'Game-ios-sticky'>
                            <div className = 'Game-title'>{this.props.appName}</div>
                        </Grid>
                        <Grid item xs={12} className = 'Game-ios-sticky'>
                            <a className = 'Game-ios-sticky' href={this.props.appStoreLocation} target="itunes_store"><img src={AppStore} alt={this.appName+' - Abnormal Head LLC'}/></a>
                        </Grid>
                    </Grid>
                </Sticky>
                );
            }
        }
        else
        {
            if(!isMobile)
            {
            return(
                <Sticky subscribe={(props) => (this.setState({ offset: props.height }))} addClassName={'small'}>
                    <Grid container spacing={1}>
                        <Grid item xs={4} className = 'Game-ios-sticky'>
                            <a className = 'Game-ios-sticky' href={this.props.appStoreLocation} target="itunes_store"><img src={AppStore}  alt={this.appName+' - Abnormal Head LLC'}/></a>
                        </Grid>
                        <Grid item xs={4} className = 'Game-ios-sticky'>
                            <div className = 'Game-title'>{this.props.appName}</div>
                        </Grid>
                        <Grid item xs={4} className = 'Game-ios-sticky'>
                            <a className = 'Game-ios-sticky' href={this.props.playStoreLocation}><img  alt='Get it on Google Play' height = {83} src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
                        </Grid>
                    </Grid>
                </Sticky>
            );
            }
            else
            {
                return(
                    <Sticky subscribe={(props) => (this.setState({ offset: props.height }))} addClassName={'small'}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} className = 'Game-ios-sticky'>
                            <div className = 'Game-title'>{this.props.appName}</div>
                        </Grid>
                        <Grid item xs={12} className = 'Game-ios-sticky'>
                            <a className = 'Game-ios-sticky' href={this.props.appStoreLocation} target="itunes_store"><img src={AppStore}  alt={this.appName+' - Abnormal Head LLC'}/></a>
                        </Grid>
                        <Grid item xs={12} className = 'Game-ios-sticky'>
                            <a className = 'Game-ios-sticky' href={this.props.playStoreLocation}><img  alt='Get it on Google Play' height = {83} src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
                        </Grid>
                    </Grid>
                </Sticky>
                );
            }
        }
    }
}

export default GameSticky;
import React from 'react';

import BaseApp from './BaseApp.js';
import AppSummary from './AppSummary.js';

class MTile extends React.Component {

    render() {

      var shot1  = require('./images/mtile/shot_1.jpg');
      var shot2  = require('./images/mtile/shot_2.jpg');
      var shot3  = require('./images/mtile/shot_3.jpg');
      var shot4  = require('./images/mtile/shot_4.jpg');


      const shortDescription =
      `If you like challenging mind bending strategy games like 2048, you will like this much better.
      `;
      const longDescription = `
      The fantastic letter tile game is back, and better than ever. If you like challenging mind bending strategy games like 2048, you will like this much better.

      A new tile comes into play with each swipe.

      Swipe left and be happy, swipe right and be glad. Swipe up and swipe down combining tiles of the same rank. Rank being the letter of the tile, with the M tile being the highest. Very few players ever get to the M tile. As the letters fill the screen, be prepared to land them so that you can combine them. The more letter tiles you combine, the more room you make for future tiles. This may remind you of 2048, but it is a very different experience.

      Many other games have followed, and many other 2048 style tile games have come and gone.

      Rack up the points, but the real question is, what tile will be your highest rank? Can you get to E, F or G? Will your friends beat you out at I or J? What is your tile rank?

      So if you played 2048, say 2048 times and are looking for a change, look no further.  `;

      if(this.props.summary === true)
      return (
        <AppSummary appName = 'M-Tile'
                        shortDescription = {shortDescription} 
                        longDescription = {longDescription}
                        appStoreLocation = "https://itunes.apple.com/us/app/M-Tile/id1378132831"
                        playStoreLocation = "https://play.google.com/store/apps/details?id=com.abnormalhead.mtile&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                        route = {"/apps/m-tile"}
                        shot = {shot1}
                        />
      );
      else
      return(
        <BaseApp appName = 'M-Tile'
                        shortDescription = {shortDescription} 
                        longDescription = {longDescription}
                        videoId = "ozf2XgT4w-4"
                        appStoreLocation = "https://itunes.apple.com/us/app/M-Tile/id1378132831"
                        playStoreLocation = "https://play.google.com/store/apps/details?id=com.abnormalhead.mtile&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                        shots = {[shot1,shot2,shot3,shot4]}
                        />
      
      );
      }
   
  }
  

export default MTile;

import React from 'react';
import Grid from '@material-ui/core/Grid';

import {
  isMobile
} from "react-device-detect";


class Privacy extends React.Component {

  render() {
    const description =
    `
    Privacy Policy
    

    Abnormal Head LLC builds mobile applications and provides related services.

    This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service.

    If you choose to use our Applications and Services, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.

    Information Collection and Use

    For a better experience, while using our Service, we may require you to provide us with certain personally identifiable information. The information that we request will be retained by us and used as described in this privacy policy.

    The applications may use third party services that may collect information used to identify you.

    We want to inform you that whenever you use our Service, in a case of an error in the app we collect data and information (through third party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other statistics.

    Security

    We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.

    Children’s Privacy

    These applications and services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.

    Changes to This Privacy Policy

    We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.
    
    Contact Us
                
    If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us.`;


    if(!isMobile)
    {
      //console.log("Desktop or Tablet");
      return (
        <div>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <div className = 'Game-short-description'>
                {description}
              </div>
            </Grid>
          </Grid>
        </div>
      );
    }
    else
    {
      //console.log("Mobile");
      return(
        <div>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <div className = 'Game-short-description'>
                {description}
              </div>
            </Grid>
          </Grid>
        </div>
      );
    }
  }
}
  

export default Privacy;

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AbnormalHead from './AbnormalHead';
import * as serviceWorker from './serviceWorker';
import ReactGA from 'react-ga';


const trackingId = "UA-125051814-1"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);
/*
ReactGA.set({
  userId: auth.currentUserId(),
  // any data that is relevant to the user session
  // that you would like to track with google analytics
})
*/
ReactDOM.render(<AbnormalHead />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
